import React from 'react'
import MenuBar from '../Menu.js/MenuBar'
import DropDownMenu from '../Menu.js/DropDownMenu'

const Test = () => {
    return (
        <>
        <MenuBar /> 
         <DropDownMenu />
        </>
    )
}

export default Test
