
  export const accordionContents =[
    {
      title: "What is Syndicate",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "What are the responsibilities of the syndicate manager?",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "Is there a maximum number of people allowed in a syndicate?",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "Payment",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "Withdrawals",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "How can we purchase ticket",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "How can I be sure the tickets have been purchased?",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
  ]


  export const accordionContentss =[
    {
      title: "REGISTRATION AND ACCOUNT",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "HOW TO PLAY",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "WINNING",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "PAYMENT",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "WITHDRAWAL",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "Does it cost any money to get registered?",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "HOW TO DESPOSIT",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "What are the minimum and maximum stakes I can place?",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
    {
      title: "How do I update my personal details?",
      content:  "Playing as a group can be a lot more fun than playing alone and celebrating a win with others can be so much more rewarding – especially when it’s a big jackpot! But first things first – get some friends, family or work colleagues together and get the ball rolling. Top tips for building your team Recruit your dream team – syndicates can be made up of friends, family or even work colleagues. In fact, two thirds of current syndicates are work based*, with syndicate managers starting them up to inject some fun and social interaction into the workplace. Agree how it will work – there are no set ways of how to play within a syndicate but agreeing guidelines upfront will provide peace of mind for your team. Stay connected – social media is a great way to keep in touch with your team in between draws. You can send your syndicate"
    },
  ]